import { FormatDate, ResetCart, ShowLoader, HideLoader, FetchPreferencesPost } from '../../functions'
import { getRequestOptions, fetchPreferences, getBanners } from '../../helpers/apiHelper'

export const setAccountData = ({ accountUserName, accountEntityID, accountEntityName, accountEntityIDReal, accountCompany, accountCompanyHashed, accountSegmentationIDs, currencyLeft, currencyRight, currencyCode, currencyDecimal, currencyThousand, localTaxNumber, warehouseID, warehouseIDReal, showStock, pricelineID, paymentid, paymentCondition, paymentName, estimatedDelivery, userID, userIDReal, warehouseAddress, socials, addressidreal }) => {
    if (localStorage.getItem('user.id') && localStorage.getItem('user.id') !== userID) ResetCart()
    localStorage.setItem('account.user.name',accountUserName)
    localStorage.setItem('account.entity.id',accountEntityID)
    localStorage.setItem('account.entity.name',accountEntityName)
    localStorage.setItem('account.entity.id.real',accountEntityIDReal)
    localStorage.setItem('account.company',accountCompany)
    localStorage.setItem('account.company.hashed',accountCompanyHashed)
    localStorage.setItem('account.segmentationids',accountSegmentationIDs)
    localStorage.setItem('currency.left',currencyLeft)
    localStorage.setItem('currency.right',currencyRight)
    localStorage.setItem('currency.code',currencyCode)
    localStorage.setItem('currency.decimal',currencyDecimal)
    localStorage.setItem('currency.thousand',currencyThousand)
    localStorage.setItem('local.tax.number',localTaxNumber)
    localStorage.setItem('warehouse.id',warehouseID ? warehouseID : 'J8RvMwowm7k2pNOr')
    localStorage.setItem('warehouse.id.real',warehouseIDReal)
    localStorage.setItem('show.stock',showStock)
    localStorage.setItem('priceline.id',pricelineID)
    localStorage.setItem('payment.id',paymentid)
    localStorage.setItem('payment.condition',paymentCondition)
    localStorage.setItem('payment.name',paymentName)
    localStorage.setItem('user.id',userID)
    localStorage.setItem('user.id.real',userIDReal)
    localStorage.setItem('warehouse.address', warehouseAddress)
    localStorage.setItem('address.id.real',addressidreal)
    let date = new Date()
    date.setDate(date.getDate() + parseInt(estimatedDelivery))
    localStorage.setItem('estimated.delivery', FormatDate(date))
    localStorage.setItem('socials', JSON.stringify(socials))
}

const compileAddressCompany = (el) => {
    return `${el?.Address1}, ${el.PostCode}, ${el.country.data.name ? el.country.data.name : ''}`
}

export const applyAccount = (entityID) => {
    ShowLoader()
    fetch(`${process.env.REACT_APP_API_DOMAIN}/entity-account/${entityID}?include=company,address,address.contact,user,company.socialValues&realKey=1&type_id=8&itemSegmentations=true`, getRequestOptions())
        .then(response => response.json())
        .then(json => {
            if (json.data) {
                const account = json.data
                const accountSegmentationIDs = (account.itemSegmentationIds?.length > 0 && Array.from(new Set(account.itemSegmentationIds)).join(',')) || '';
                const segmentationIDs = accountSegmentationIDs.length > 1 ? accountSegmentationIDs.split(',') : [accountSegmentationIDs]
                getBanners(segmentationIDs.map(i => '&item_segmentations[]=' + i).join(''))
                    .then(json => {
                        json.data && localStorage.setItem('banners_data', JSON.stringify(json.data))
                    })
                    .catch(error => console.error(error))
                    .finally(() => {
                        setAccountData({
                            accountUserName: account.user?.data?.Name || '',
                            accountEntityID: account.id,
                            accountEntityName: account.matchcode,
                            accountEntityIDReal: entityID,
                            accountCompany: account.company?.data?.id,
                            accountCompanyHashed: account.company?.data?.hashed_id,
                            accountSegmentationIDs: (account.itemSegmentationIds?.length > 0 && Array.from(new Set(account.itemSegmentationIds)).join(',')) || '',
                            currencyLeft: account.currency?.left || '',
                            currencyRight: account.currency?.right || '',
                            currencyCode: account.currency?.code || '',
                            currencyDecimal: account.currency?.decimal_point || '',
                            currencyThousand: account.currency?.thousand_point || '',
                            localTaxNumber: account.local_tax_number || '',
                            warehouseID: account.company?.data?.WarehouseID || 'J8RvMwowm7k2pNOr',
                            warehouseIDReal: account.company?.data?.RealWarehouseID || 0,
                            showStock: (account.extrafieldValueable && account.extrafieldValueable[0]?.value) || 2,
                            pricelineID: account.source_price_id || account.company?.data?.default_priceline_id || '',
                            paymentid: account.payment_type_id_real || 0,
                            paymentCondition: account.payment_condition_matchcode || '',
                            paymentName: account.payment_type_name || '',
                            estimatedDelivery: account.estimated_delivery || 1,
                            userID: account.user?.data?.id || '',
                            userIDReal: account.user?.data?.UserID || 0,
                            warehouseAddress: account.company?.data?.Address1 && account.company?.data?.PostCode ? compileAddressCompany(account.company?.data) : '',
                            socials: account.company?.data?.socialValues?.data || [],
                            addressidreal: account.address_id_real,
                        })
                        localStorage.setItem('username', account.user.data.username)
                        localStorage.setItem('enter.shop', '1')
                        localStorage.setItem('address.object', JSON.stringify(account.address.data))
                        localStorage.setItem('entity_id', JSON.stringify({ entityId: account.entity_id, entityIdReal: account.entity_id_real }))
                        localStorage.setItem('payment_condition_matchcode_translations', JSON.stringify(account.payment_condition_matchcode_translations))
                        localStorage.setItem('payment_type_name_translations', JSON.stringify(account.payment_type_name_translations))
                        localStorage.setItem('entity_account.external_id', account.external_id)
                        localStorage.setItem('marketingCampaignIds', JSON.stringify(account.marketingCampaignIds))
                        localStorage.setItem('marketingCampaignUpdateAt', new Date().toISOString().slice(0, 19).replace('T', ' '))
                        localStorage.setItem('timeSpentMarketingCampaign', new Date().getTime() + 2 * 60 * 60 * 1000)
                        
                        fetchPreferences()
                            .then(json => {
                                if (json.data) {
                                    !localStorage.getItem('locale') && localStorage.setItem('locale',JSON.parse(json.data[0]?.preferences)['language'])
                                    localStorage.setItem('enter.shop','1')
                                    window.location.href = '/'
                                } else {
                                    FetchPreferencesPost()
                                    .then(json => {
                                        if (json.data) {
                                            localStorage.setItem('enter.shop','1')
                                            window.location.href = '/'
                                        }
                                    })
                                }
                                localStorage.removeItem('entity_accounts')
                            })
                        })
                
            }
    })
    .catch(error => console.error(error))
    .finally(() => HideLoader())
}